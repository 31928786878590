import * as React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Container } from "react-bootstrap"

// import fastForwardLogo from "../images/fast-forward.svg"

const IndexPage = () => (
  <Layout>
    <Seo title="Gender Affirming Voice Therapy Group Acknowledgement" />

    <div className="masthead mb-5">
      <Container className="singleCol text-center">
        <h1>Gender Affirming Voice Therapy Group Acknowledgement</h1>
        
      </Container>
    </div>
    
    <Container className="singleCol">
    
    </Container>
    
  </Layout>
)

export default IndexPage
